/*!
 * Start Bootstrap - Landing Page v5.0.8 (https://startbootstrap.com/themes/landing-page)
 * Copyright 2013-2020 Start Bootstrap
 * Licensed under MIT (https://github.com/StartBootstrap/startbootstrap-landing-page/blob/master/LICENSE)
 */

body {
  font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 700;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
}

.navbar {
  background-color: #165EBA !important;
  /* background-color:#f8f9fa!important; */
  font-size: 1.25rem;
  color: white !important;
}

.navbar a {
  color: white !important;
}

.navbar-brand {
  color: white !important;
  font-size: 1.5rem;
}

.navbar-nav .nav-link.active {
  /* color: #ffcc00; */
  font-weight: bold;
}

header.masthead {
  position: relative;
  background-color: #343a40;
  background: url("./img/cardiac-pulse.jpg") no-repeat center center;
  background-size: cover;
  padding-top: 8rem;
  padding-bottom: 8rem;
}

header.masthead .overlay {
  position: absolute;
  background-color: #212529;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0.3;
}

header.masthead h1 {
  font-size: 2rem;
}

@media (min-width: 768px) {
  header.masthead {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }
  header.masthead h1 {
    font-size: 3rem;
  }
}

.showcase .showcase-text {
  padding: 3rem;
}

.showcase .showcase-img {
  min-height: 30rem;
  background-size: cover;
}

@media (min-width: 768px) {
  .showcase .showcase-text {
    padding: 7rem;
  }
}

.features-icons {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.features-icons .features-icons-item {
  max-width: 20rem;
}

.features-icons .features-icons-item .features-icons-icon {
  height: 7rem;
}

.features-icons .features-icons-item .features-icons-icon i {
  font-size: 4.5rem;
}

.features-icons .features-icons-item:hover .features-icons-icon i {
  font-size: 5rem;
}

.testimonials {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

/*.testimonials .testimonial-item {
  max-width: 18rem;
}*/

.testimonials .testimonial-item img {
  max-width: 12rem;
  box-shadow: 0px 5px 5px 0px #adb5bd;
}

.call-to-action {
  position: relative;
  background-color: #343a40;
  background: url("./img/cardiac-pulse.jpg") no-repeat center center;
  background-size: cover;
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.call-to-action .overlay {
  position: absolute;
  background-color: #212529;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0.3;
}

#global-container {
  position: relative;
  min-height: 100vh;
}

.content-wrap {
  padding-bottom: 7rem;
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  /* height: 7rem; */
  height: 3rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
}

#network_viz {
  height: 700px;
}

#node_information {
  height: 700px;
  overflow-y: auto;
}

.table {
  overflow: auto;
}

.item-panel {
  width: 100px;
  height: 100px;
  margin: 20px 0px;
  background: lightgray;
  border-radius: 20px;
  text-align: center;
  border-width: 2px;
  border-color: black;
  border-style: solid;
  background: #7abaff;
}

.item-panel:hover {
  background: orange;
}

.item-panel .fa {
  padding-top: 10px;
  font-size: 60px;
  display: block;
}

.vocab_selection > * {
  margin: 10px;
}

.highlight {
  background-color: yellow;
}

#time_taken, #entity_count {
  margin-top: 6px;
  font-size: 0.9rem;
}


/* Semantic Search Crosswalk modal */

.crosswalk-modal {
  z-index: 9999 !important;
}

.crosswalk-modal .modal-backdrop {
  z-index: 9999 !important;
}

.crosswalk-modal .modal-dialog {
  max-width: 80%;
}