/* Center the pagination and adjust the appearance */
.ui.pagination.menu {
    display: flex;
    justify-content: center;
    padding: 5px;
}

/* Style each pagination item */
.ui.pagination.menu .item {
    color: #666 !important;
    background-color: #f9f9f9 !important;
    border-radius: 2px !important;
    /* margin: 0 3px; */
    border: none !important;
    padding: .5em 1em;
    text-decoration: none !important;
}

/* Style the active pagination item */
.ui.pagination.menu .active.item {
    background-color: #e0e0e0 !important;
    color: #000 !important;
}

/* Add hover effects for pagination items */
.ui.pagination.menu .item:hover {
    background-color: #ddd !important;
}

/* Adjust the cursor */
.ui.pagination.menu .item {
    cursor: pointer;
}

/* Style the ellipsis item */
.ui.pagination.menu .item[type="ellipsisItem"] {
    pointer-events: none; /* Make it non-clickable */
    color: #888; /* Adjust color to make it appear disabled */ 
    cursor: default; /* Change cursor to indicate it's not clickable */
    padding: .5em 1em; /* Adjust padding */
}
