/*!
 * Start Bootstrap - Landing Page v5.0.8 (https://startbootstrap.com/themes/landing-page)
 * Copyright 2013-2020 Start Bootstrap
 * Licensed under MIT (https://github.com/StartBootstrap/startbootstrap-landing-page/blob/master/LICENSE)
 */

body {
  font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 700;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
}

.navbar {
  background-color: #165EBA !important;
  /* background-color:#f8f9fa!important; */
  font-size: 1.25rem;
  color: white !important;
}

.navbar a {
  color: white !important;
}

.navbar-brand {
  color: white !important;
  font-size: 1.5rem;
}

.navbar-nav .nav-link.active {
  /* color: #ffcc00; */
  font-weight: bold;
}

header.masthead {
  position: relative;
  background-color: #343a40;
  background: url(/static/media/cardiac-pulse.9c239366.jpg) no-repeat center center;
  background-size: cover;
  padding-top: 8rem;
  padding-bottom: 8rem;
}

header.masthead .overlay {
  position: absolute;
  background-color: #212529;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0.3;
}

header.masthead h1 {
  font-size: 2rem;
}

@media (min-width: 768px) {
  header.masthead {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }
  header.masthead h1 {
    font-size: 3rem;
  }
}

.showcase .showcase-text {
  padding: 3rem;
}

.showcase .showcase-img {
  min-height: 30rem;
  background-size: cover;
}

@media (min-width: 768px) {
  .showcase .showcase-text {
    padding: 7rem;
  }
}

.features-icons {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.features-icons .features-icons-item {
  max-width: 20rem;
}

.features-icons .features-icons-item .features-icons-icon {
  height: 7rem;
}

.features-icons .features-icons-item .features-icons-icon i {
  font-size: 4.5rem;
}

.features-icons .features-icons-item:hover .features-icons-icon i {
  font-size: 5rem;
}

.testimonials {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

/*.testimonials .testimonial-item {
  max-width: 18rem;
}*/

.testimonials .testimonial-item img {
  max-width: 12rem;
  box-shadow: 0px 5px 5px 0px #adb5bd;
}

.call-to-action {
  position: relative;
  background-color: #343a40;
  background: url(/static/media/cardiac-pulse.9c239366.jpg) no-repeat center center;
  background-size: cover;
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.call-to-action .overlay {
  position: absolute;
  background-color: #212529;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0.3;
}

#global-container {
  position: relative;
  min-height: 100vh;
}

.content-wrap {
  padding-bottom: 7rem;
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  /* height: 7rem; */
  height: 3rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
}

#network_viz {
  height: 700px;
}

#node_information {
  height: 700px;
  overflow-y: auto;
}

.table {
  overflow: auto;
}

.item-panel {
  width: 100px;
  height: 100px;
  margin: 20px 0px;
  background: lightgray;
  border-radius: 20px;
  text-align: center;
  border-width: 2px;
  border-color: black;
  border-style: solid;
  background: #7abaff;
}

.item-panel:hover {
  background: orange;
}

.item-panel .fa {
  padding-top: 10px;
  font-size: 60px;
  display: block;
}

.vocab_selection > * {
  margin: 10px;
}

.highlight {
  background-color: yellow;
}

#time_taken, #entity_count {
  margin-top: 6px;
  font-size: 0.9rem;
}


/* Semantic Search Crosswalk modal */

.crosswalk-modal {
  z-index: 9999 !important;
}

.crosswalk-modal .modal-backdrop {
  z-index: 9999 !important;
}

.crosswalk-modal .modal-dialog {
  max-width: 80%;
}
.rsdt-paginate {
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 5px;
}

button.rsdt-paginate.button {
  font-family: inherit;
  font-size: 100%;
  padding: 0.5em 1em;
  color: #666 !important;
  color: rgba(0, 0, 0, 0.8);
  border: 1px solid #999;
  border: transparent;
  background-color: #f9f9f9;
  text-decoration: none;
  border-radius: 2px;
  display: inline-block;
  zoom: 1;
  white-space: nowrap;
  vertical-align: middle;
  text-align: center;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  user-select: none;
}

button.rsdt-paginate.button:not(:disabled) {
  cursor: pointer;
}

button.rsdt-paginate.button:hover:not(:disabled) {
  color: #444 !important;
  background-color: #dfdfdf;
}

button.rsdt-paginate.active {
  color: #333 !important;
  background-color: #e9e9e9;
}

.rsdt.rsdt-column-toggles {
  margin-bottom: 20px;
}

.rsdt.rsdt-column-toggles.toggle {
  display: inline-block;
  margin-left: 5px;
  margin-right: 10px;
  margin-bottom: 0;
}

.rsdt.rsdt-column-toggles.toggle > label {
  margin-left: 5px;
  cursor: pointer !important;
  cursor: hand !important;
  -webkit-user-select: none;
          user-select: none;
}

.rsdt {
  box-sizing: border-box;
}

.rsdt *,
.rsdt *::before,
.rsdt *::after {
  box-sizing: inherit;
}

.rsdt.rsdt-container {
  overflow: auto;
}

.rsdt.rsdt-highlight {
  background-color: yellow;
}

.rsdt.rsdt-sortable {
  margin-left: 10px;
}

.rsdt.rsdt-sortable-icon::after {
  font-size: 20px;
  font-style: normal;
  content: '\21D5';
  cursor: pointer;
}

.rsdt.rsdt-sortable-asc::after {
  font-size: 20px;
  font-style: normal;
  content: '\2193';
  cursor: pointer;
}

.rsdt.rsdt-sortable-desc::after {
  font-size: 20px;
  font-style: normal;
  content: '\2191';
  cursor: pointer;
}


/* Center the pagination and adjust the appearance */
.ui.pagination.menu {
    display: flex;
    justify-content: center;
    padding: 5px;
}

/* Style each pagination item */
.ui.pagination.menu .item {
    color: #666 !important;
    background-color: #f9f9f9 !important;
    border-radius: 2px !important;
    /* margin: 0 3px; */
    border: none !important;
    padding: .5em 1em;
    text-decoration: none !important;
}

/* Style the active pagination item */
.ui.pagination.menu .active.item {
    background-color: #e0e0e0 !important;
    color: #000 !important;
}

/* Add hover effects for pagination items */
.ui.pagination.menu .item:hover {
    background-color: #ddd !important;
}

/* Adjust the cursor */
.ui.pagination.menu .item {
    cursor: pointer;
}

/* Style the ellipsis item */
.ui.pagination.menu .item[type="ellipsisItem"] {
    pointer-events: none; /* Make it non-clickable */
    color: #888; /* Adjust color to make it appear disabled */ 
    cursor: default; /* Change cursor to indicate it's not clickable */
    padding: .5em 1em; /* Adjust padding */
}

.dropdown {
    position: relative;
    display: inline-block;
    background-color: #f8f9fa;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: white;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    height: 500px;
    width: 500px;
    overflow-y: auto;  /* Auto-scrolling vertically */
    overflow-x: hidden;  /* No scrolling horizontally */
}

.dropdown-content > li {
    color: black;
    padding: 5px 5px;
    margin: 0px;
    text-decoration: none;
    display: block;
    font-size: 15px;
}

.dropdown-content p {
    margin: 0px;
}

.dropdown-content > li:hover {
    background-color: #ddd;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown:hover {
    background-color: #d3d9de;
}

.interactiveText {
    font-size: 17px;
    padding: 10px;
    margin-bottom: 10px;

    border-style: ridge;
    border-width: 2px;

}

.label-table {
    width: 100%;
    min-height: 200px;
}

table {
    width: 100%;
}
/* narrative-compare.css */
.narrative-compare-container {
    padding: 20px;
}

.access-key-section label {
    margin-right: 10px;
}

.search-button-section {
    margin-bottom: 20px;
}

.narrative-pane {
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 5px;
    background-color: #f9f9f9;
    height: 100%;
}

.similarity-slider-container {
  width: 80%;
  margin: 20px auto;
  text-align: center;
}

.similarity-slider {
  position: relative;
  height: 8px;
  border-radius: 4px;
  margin-top: 20px;
  overflow: hidden;
}

.slider-thumb {
  position: absolute;
  top: 50%;
  width: 20px;
  height: 20px;
  background-color: #1E90FF; /* Dodger Blue */
  border: 2px solid #FFFFFF;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  transition: left 0.3s ease;
  z-index: 2;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.slider-labels {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.slider-labels span {
  font-size: 14px;
  color: #757575;
}

.slider-value {
  font-size: 18px;
  font-weight: bold;
  margin-top: 15px;
  color: #333333;
}

.weighting-panel {
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    padding: 1rem;
    margin-bottom: 1rem;
}

.entity-weight-container {
    display: flex;
    flex-direction: column;
}

.entity-label {
    font-weight: bold;
    margin-bottom: 0.25rem;
}

.slider-container {
    display: flex;
    align-items: center;
}

.form-range {
    flex-grow: 1;
    width: auto !important;
    margin-right: 0.5rem;
}

.weight-value {
    min-width: 2rem;
    text-align: right;
}
.digital-twin-container {
    padding: 20px;
}

.results-container {
    margin-top: 20px;
}

.results-box {
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    padding: 15px;
    max-height: 500px;
    overflow-y: auto;
    white-space: pre-wrap;
    word-wrap: break-word;
}

.search-button-section {
    margin: 20px 0;
}

.access-key-section {
    margin-bottom: 20px;
} 
