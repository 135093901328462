.dropdown {
    position: relative;
    display: inline-block;
    background-color: #f8f9fa;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: white;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    height: 500px;
    width: 500px;
    overflow-y: auto;  /* Auto-scrolling vertically */
    overflow-x: hidden;  /* No scrolling horizontally */
}

.dropdown-content > li {
    color: black;
    padding: 5px 5px;
    margin: 0px;
    text-decoration: none;
    display: block;
    font-size: 15px;
}

.dropdown-content p {
    margin: 0px;
}

.dropdown-content > li:hover {
    background-color: #ddd;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown:hover {
    background-color: #d3d9de;
}

.interactiveText {
    font-size: 17px;
    padding: 10px;
    margin-bottom: 10px;

    border-style: ridge;
    border-width: 2px;

}

.label-table {
    width: 100%;
    min-height: 200px;
}

table {
    width: 100%;
}