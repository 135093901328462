.rsdt-paginate {
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 5px;
}

button.rsdt-paginate.button {
  font-family: inherit;
  font-size: 100%;
  padding: 0.5em 1em;
  color: #666 !important;
  color: rgba(0, 0, 0, 0.8);
  border: 1px solid #999;
  border: transparent;
  background-color: #f9f9f9;
  text-decoration: none;
  border-radius: 2px;
  display: inline-block;
  zoom: 1;
  white-space: nowrap;
  vertical-align: middle;
  text-align: center;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

button.rsdt-paginate.button:not(:disabled) {
  cursor: pointer;
}

button.rsdt-paginate.button:hover:not(:disabled) {
  color: #444 !important;
  background-color: #dfdfdf;
}

button.rsdt-paginate.active {
  color: #333 !important;
  background-color: #e9e9e9;
}

.rsdt.rsdt-column-toggles {
  margin-bottom: 20px;
}

.rsdt.rsdt-column-toggles.toggle {
  display: inline-block;
  margin-left: 5px;
  margin-right: 10px;
  margin-bottom: 0;
}

.rsdt.rsdt-column-toggles.toggle > label {
  margin-left: 5px;
  cursor: pointer !important;
  cursor: hand !important;
  user-select: none;
}

.rsdt {
  box-sizing: border-box;
}

.rsdt *,
.rsdt *::before,
.rsdt *::after {
  box-sizing: inherit;
}

.rsdt.rsdt-container {
  overflow: auto;
}

.rsdt.rsdt-highlight {
  background-color: yellow;
}

.rsdt.rsdt-sortable {
  margin-left: 10px;
}

.rsdt.rsdt-sortable-icon::after {
  font-size: 20px;
  font-style: normal;
  content: '\21D5';
  cursor: pointer;
}

.rsdt.rsdt-sortable-asc::after {
  font-size: 20px;
  font-style: normal;
  content: '\2193';
  cursor: pointer;
}

.rsdt.rsdt-sortable-desc::after {
  font-size: 20px;
  font-style: normal;
  content: '\2191';
  cursor: pointer;
}

