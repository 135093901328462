.digital-twin-container {
    padding: 20px;
}

.results-container {
    margin-top: 20px;
}

.results-box {
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    padding: 15px;
    max-height: 500px;
    overflow-y: auto;
    white-space: pre-wrap;
    word-wrap: break-word;
}

.search-button-section {
    margin: 20px 0;
}

.access-key-section {
    margin-bottom: 20px;
} 