/* narrative-compare.css */
.narrative-compare-container {
    padding: 20px;
}

.access-key-section label {
    margin-right: 10px;
}

.search-button-section {
    margin-bottom: 20px;
}

.narrative-pane {
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 5px;
    background-color: #f9f9f9;
    height: 100%;
}

.similarity-slider-container {
  width: 80%;
  margin: 20px auto;
  text-align: center;
}

.similarity-slider {
  position: relative;
  height: 8px;
  border-radius: 4px;
  margin-top: 20px;
  overflow: hidden;
}

.slider-thumb {
  position: absolute;
  top: 50%;
  width: 20px;
  height: 20px;
  background-color: #1E90FF; /* Dodger Blue */
  border: 2px solid #FFFFFF;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  transition: left 0.3s ease;
  z-index: 2;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.slider-labels {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.slider-labels span {
  font-size: 14px;
  color: #757575;
}

.slider-value {
  font-size: 18px;
  font-weight: bold;
  margin-top: 15px;
  color: #333333;
}

.weighting-panel {
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    padding: 1rem;
    margin-bottom: 1rem;
}

.entity-weight-container {
    display: flex;
    flex-direction: column;
}

.entity-label {
    font-weight: bold;
    margin-bottom: 0.25rem;
}

.slider-container {
    display: flex;
    align-items: center;
}

.form-range {
    flex-grow: 1;
    width: auto !important;
    margin-right: 0.5rem;
}

.weight-value {
    min-width: 2rem;
    text-align: right;
}